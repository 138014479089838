import { VFC } from "react";
import { EasyConfirmDialog } from "./EasyConfirmDialog";
import { EasyLogWorkDialog } from "./EasyLogWork";
import { EasyReschedulator } from "./EasyReschedulator";

// TODO: all of these components are 90% the same code.  There should be an easy way to make this fairly generic.
export const EasyAccessElements: VFC = () => (
  <>
    <EasyReschedulator />
    <EasyConfirmDialog />
    <EasyLogWorkDialog />
  </>
);
